import React from "react";
import styled from "styled-components";

import {
  StyledBlocksContent,
  Image,
  Button,
  Video,
  BlocksContent,
} from "@global";
import { Container, P, H2, StyledOverlay, HeroH3 } from "@util/standard";
import { isSanityImageWithMeta } from "@util/types";
import {
  Maybe,
  SanityFullWidthContent,
  SanityImageWithMetaOrVideo,
} from "@graphql-types";
import { colorsRGB, colors } from "@util/constants";
import { ReactNode } from "react";

const BlocksContentContainer = styled(Container)``;

const TitleContainer = styled(Container)`
  text-align: left;
  flex-direction: column;

  h4 {
    margin-block-start: 0px;
    margin-block-end: 0em;
    color: ${colorsRGB.white(0.5)};
  }

  h2 {
    margin-block-start: 8px;
    margin-block-end: 0em;
    color: ${colors.white};
  }

  p {
    color: ${colors.white};
  }
`;

interface Props {
  content: SanityFullWidthContent;
  customHeight?: string;
  customMobileHeight?: string;
}

const RenderImageOrVideo = ({
  imageOrVideo,
  children,
}: {
  imageOrVideo: Maybe<SanityImageWithMetaOrVideo> | undefined;
  children: ReactNode;
}) => {
  if (imageOrVideo == null) {
    return null;
  }
  if (isSanityImageWithMeta(imageOrVideo)) {
    return (
      <Image
        imageData={imageOrVideo.asset?.gatsbyImageData}
        altText="carousel-image"
        isBackground
        width="100%"
        minHeight="450px"
        objectFit="contain"
      >
        {children}
      </Image>
    );
  }

  return <Video videoSrcURL={imageOrVideo.url} />;
};

function CarouselPreview({ content, customHeight, customMobileHeight }: Props) {
  if (content == null) {
    return <P color="errorRed">Error loading case study</P>;
  }

  const {
    backgroundMedia,
    blockDescription,
    title,
    heading,
    link,
    blockStyledDescription,
  } = content;
  const imageOrVideo = backgroundMedia && backgroundMedia[0];

  return (
    <Container>
      <RenderImageOrVideo imageOrVideo={imageOrVideo}>
        <Container
          width="100vw"
          height={customHeight ? customHeight : "auto"}
          margin="0px"
          display="flex"
          flexDirection="row"
          mobileDimensions={{
            height: customMobileHeight ? customMobileHeight : `600px`,
          }}
        >
          <Container width="50%" tabletWidth="100%" flexDirection="row">
            <Container
              width="100%"
              maxWidth="340px"
              margin="auto"
              display="flex"
              flexDirection="column"
              zIndex={20}
              mobileWidth="80%"
              mobileMargin="57px auto"
            >
              <TitleContainer>
                <HeroH3>{heading?.toUpperCase()}</HeroH3>
                <H2 fontWeight="normal">{title}</H2>
                <BlocksContentContainer>
                  <BlocksContent blocks={blockDescription?._rawBlocks} />
                </BlocksContentContainer>
                {link && link.url && (
                  <Button
                    theme="white"
                    linkTo={link.url}
                    text={link.linktext as string}
                  />
                )}
              </TitleContainer>
            </Container>
          </Container>

          <StyledOverlay height="100%" />
        </Container>
      </RenderImageOrVideo>
    </Container>
  );
}

export default CarouselPreview;
