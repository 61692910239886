import Hero from "@shared/hero";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import ProductPreview from "@shared/productPreview";
import { Container, FlexGrid } from "@util/standard";
import React from "react";
import { pageWidthMargin } from "@util/helper";
import { HeroCarousel, Breadcrumb } from "@global";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { graphql, PageProps } from "gatsby";
import { BreadcrumbPageContext } from "@util/types";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  pageContext: BreadcrumbPageContext;
  data: Query;
}

const GiftingLanding = ({ pageContext, data }: Props) => {
  const landingData = data.allSanityGiftingLandingPage.nodes[0];
  if (landingData == null) return null;
  const margin = pageWidthMargin();

  return (
    <>
      <SEO seoData={landingData.seo} />
      <Layout>
        <Container flexDirection="column">
          {landingData &&
          landingData?.hero &&
          landingData.additionalHeros &&
          landingData.additionalHeros.length > 0 ? (
            <HeroCarousel
              hero={landingData?.hero}
              additionalHeros={landingData.additionalHeros}
            />
          ) : (
            <Hero sanityHero={landingData?.hero} />
          )}
          <Breadcrumb pageContext={pageContext} />

          <Container
            width={`calc(100% - ${margin} - ${margin})`}
            margin="60px auto"
          >
            <FlexGrid
              gridMargin="45px"
              laptopGridMargin="35px"
              tabletGridMargin="20px"
            >
              {landingData &&
                landingData.products &&
                landingData.products.length > 0 &&
                landingData.products.map(product => {
                  if (!product) return;
                  return (
                    <ProductPreview
                      key={product.id}
                      noMargin={true}
                      marginBottom="55px"
                      product={product}
                      isGift
                    />
                  );
                })}
              {landingData &&
                landingData.productsAu &&
                landingData.productsAu.length > 0 &&
                landingData.productsAu.map(product => {
                  if (!product) return;
                  return (
                    <ProductPreview
                      key={product.id}
                      noMargin={true}
                      marginBottom="55px"
                      product={product}
                      isGift
                    />
                  );
                })}
            </FlexGrid>
          </Container>

          <Container>
            <ImageTextBlockWithCarousel
              singleBlockData={landingData.corporateGifting}
            />
          </Container>
        </Container>
      </Layout>
    </>
  );
};

export default GiftingLanding;

export const query = graphql`
  query giftingLanding($iban: String) {
    allSanityGiftingLandingPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        ...sanityGiftingLanding
      }
    }
  }
`;
