import { Container, H2 } from "@util/standard";
import * as React from "react";
import styled from "styled-components";
import { LAPTOP_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import NewsletterForm from "@shared/newsletterForm";

const StyledContainer = styled(Container)`
  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    max-width: 998px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 500px;
    flex-flow: column nowrap;
    padding: 0 30px;
    align-items: flex-start;
  }
`;

const NewsletterCTA = () => {
  return (
    <Container width="100%" backgroundColor="barkersDarkGray">
      <StyledContainer
        width="100%"
        maxWidth="1200px"
        margin="50px auto"
        padding="30px"
        alignItems="center"
        justifyContent="center"
      >
        <Container width="33.333%" tabletWidth="100%">
          <H2 color="white">Subscribe to our newsletter for updates</H2>
        </Container>
        <NewsletterForm isDark={false} />
      </StyledContainer>
    </Container>
  );
};

export default NewsletterCTA;
